// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$.tsx");
  import.meta.hot.lastModified = "1718830289043.9336";
}
// REMIX HMR END

import { GeneralErrorBoundary } from '#app/components/error-boundary';
export async function loader() {
  throw new Response('Not found', {
    status: 404
  });
}
export default function NotFound() {
  return <GeneralErrorBoundary />;
}
_c = NotFound;
export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
_c2 = ErrorBoundary;
var _c, _c2;
$RefreshReg$(_c, "NotFound");
$RefreshReg$(_c2, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;