// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/error-boundary.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/error-boundary.tsx");
}
// REMIX HMR END

import { Link, isRouteErrorResponse, useRouteError } from '@remix-run/react';
import classes from './error-boundary.module.css';
import { Container, Title, Text, Group, Button, Code } from '@mantine/core';

// function getErrorMessage( error: unknown ) {
// 	if ( typeof error === 'string' ) return error
// 	if (
// 		error &&
// 		typeof error === 'object' &&
// 		'message' in error &&
// 		typeof error.message === 'string'
// 	) {
// 		return error.message
// 	}
// 	console.error( 'Unable to get error message for error', error )
// 	return 'Unknown Error'
// }

// type StatusHandler = ( info: {
// 	error: ErrorResponse
// 	params: Record<string, string | undefined>
// } ) => JSX.Element | null

// export function GeneralErrorBoundary( {
// 	defaultStatusHandler = ( { error } ) => (
// 		<p>
// 			{ error.status } { error.data }
// 		</p>
// 	),
// 	statusHandlers,
// 	unexpectedErrorHandler = error => <p>{ getErrorMessage( error ) }</p>,
// }: {
// 	defaultStatusHandler?: StatusHandler
// 	statusHandlers?: Record<number, StatusHandler>
// 	unexpectedErrorHandler?: ( error: unknown ) => JSX.Element | null
// } ) {
// 	const error = useRouteError()
// 	const params = useParams()

// 	if ( typeof document !== 'undefined' ) {
// 		console.error( error )
// 	}

// 	return (
// 		<div>
// 			{ isRouteErrorResponse( error )
// 				? ( statusHandlers?.[ error.status ] ?? defaultStatusHandler )( {
// 					error,
// 					params,
// 				} )
// 				: unexpectedErrorHandler( error ) }
// 		</div>
// 	)
// }

export function GeneralErrorBoundary() {
  _s();
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    return <Container py={80}>
				<Title order={1} className={classes.title}>
					{error.status} {error.statusText}
				</Title>
				<Text c="dimmed" size="lg" ta="center" className={classes.description}>
					{error.data}
				</Text>
				<Group justify="center">
					<Button component={Link} to="/" variant="subtle" size="md" className={classes.button}>
						Take me back to home page
					</Button>
				</Group>
			</Container>;
  } else if (error instanceof Error) {
    return <Container py={80}>
				<Title order={1} className={classes.title}>
					Error
				</Title>
				<Text c="dimmed" size="lg" ta="center" className={classes.description}>
					{error.message}
				</Text>
				<Code c="dimmed">{error.stack}</Code>
				<Group justify="center">
					<Button component={Link} to="/" variant="subtle" size="md" className={classes.button}>
						Take me back to home page
					</Button>
				</Group>
			</Container>;
  } else {
    return <Container py={80}>
				<Title order={1} className={classes.title}>
					Error
				</Title>
				<Text c="dimmed" size="lg" ta="center" className={classes.description}>
					Unknown Error
				</Text>
				<Group justify="center">
					<Button component={Link} to="/" variant="subtle" size="md" className={classes.button}>
						Take me back to home page
					</Button>
				</Group>
			</Container>;
  }
}
_s(GeneralErrorBoundary, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c = GeneralErrorBoundary;
var _c;
$RefreshReg$(_c, "GeneralErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;